import React, { useState, useEffect } from 'react';
import './Blogs.css'
import BlogCard from './BlogCard'
import { useBlogContext } from '../../Context/BlogContext'
import Shipping from '../Shipping';

export default function Blogs() {

   const { blogs, loadingBlogs, blogError } = useBlogContext();

   if (loadingBlogs) return <p>Loading blogs...</p>;
   if (blogError) return <p>Error loading blogs: {blogError.message}</p>;

  return (
    <>
    <Shipping />
    <div id='blogs'>
        <div style={{margin: '1rem auto'}}>
            <p id='read-the'>read the</p>
            <p id='blog-txt'>BLOG</p>
        </div>
        <div id='blog-list'>

            {blogs.blogs.nodes.map((blog, i) =>
            blog.articles.nodes.map((article, j) => {
                const globalId = article.id;
                const id = globalId.split('/').pop();
                return(
                    <BlogCard data={article} id={id} key={id} category={blog.title} />
                )
            })
            )}

        </div>
     
    </div>
    </>
  )
}
