import React from 'react'
import './Shipping.css'
import van from '../Assets/icons/free-shipping.png'

export default function Shipping() {
  return (
    <div id='shipping'>
      <img id='van' src={van}/>
      <p style={{margin: 0}}>Free shipping on orders of 2 bottles or more</p>
    </div>
  )
}
