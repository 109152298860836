import React from 'react'
import './Allure.css'
import barrels from '../../../Assets/story-barrels.webp'
import { Link } from 'react-router-dom'

export default function Allure() {
  return (
    <div id='allure'>

      <img src={barrels} id='barrels-story' alt='Thiago Tequila Flavoured Barrels'/>

      <div id='allure-container'>
        <p id='the-allure'>The Allure</p>
        <p id='of-reposado'>of reposado</p>

        <p className='allure-txt'>Reposado meaning "rested" in Spanish, is the golden child of the tequila family. Aged for six months in oak barrels, resulting in a luxuriously smooth tequila perfect for sipping.</p>

        <Link className='thiago-btn' to={'/products'}>
        Join the Party
        </Link>
      </div>

      <img src={barrels} id='barrels-story-mobile' alt='Thiago Tequila Flavoured Barrels'/>

        
    </div>
  )
}
